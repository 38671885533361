import * as styled from './styles.js';
import Slider from 'react-slick';
import BannerItem from './BannerItem/BannerItem.jsx';

const bannerContent = [
	{
		id: 1,
		text: 'New Items!!!!',
		color: `#E31837`,
		backgroundColor: `#FFB81C`,
	},
	{
		id: 2,
		text: 'New Items!!!!',
		color: `#174885`,
		backgroundColor: '#c0995a',
	},
	{
		id: 3,
		text: 'New Items!!!!',
		color: '#002F65',
		backgroundColor: '#999999',
	},
	{
		id: 4,
		text: 'New Items!!!!',
		color: '#cf3339',
		backgroundColor: '#62cbc9',
	},
];

const Banner = () => {
	const settings = {
		arrows: false,
		dots: false,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		infinite: true,
		autoplaySpeed: 4500,
		speed: 1500,
		cssEase: 'linear',
	};
	return (
		<styled.BannerWrapper>
			<Slider {...settings} style={{ height: '100%', width: '100%' }}>
				{bannerContent.map((banner) => {
					return (
						<BannerItem
							key={banner.id}
							text={banner.text}
							font={banner.font}
							color={banner.color}
							backgroundColor={banner.backgroundColor}
						/>
					);
				})}
			</Slider>
		</styled.BannerWrapper>
	);
};
export default Banner;
