import styled from 'styled-components';
import { motion } from 'framer-motion';

export const FeaturedCardWrapper = styled(motion.article)`
	width: clamp(200px, 225px, 250px);
	height: 400px;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;

	background-color: ${(props) => props.theme.colors.whiteOpaque};
`;

export const FeaturedCardHeader = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;

	border-top-left-radius: 15px;
	border-top-right-radius: 15px;
	background-color: ${(props) => props.theme.colors.brandColorAccent};

	padding: 5px 10px;
	width: 100%;
`;

export const FeaturedTitle = styled.h3`
	color: ${(props) => props.theme.colors.offWhite};
`;

export const Price = styled.h4`
	color: ${(props) => props.theme.colors.offWhite};

	text-align: end;
`;

export const FeaturedImage = styled.img`
	width: 230px;
	height: 230px;

	object-fit: cover;
	object-position: 65% 50%;

	transition: all 1s ease-out;

	&:hover {
		content: url(${(props) => props.hoverImg});
	}
`;

export const SizeOptionsWrapper = styled.div`
	width: 100%;
	border: 1px solid hotpink;
`;

export const SizeOptions = styled.form`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	> select,
	select:active {
		border: none;
	}
`;

export const FeaturedCardButtonWrapper = styled.div`
	width: 100%;

	display: flex;
	justify-content: space-around;
	align-items: center;
`;
export const Button = styled(motion.div)`
	width: 100%;
`;
