import { BiSlider } from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';
import FilteredSearch from '../FilteredSearch';
import { closeFilter, openFilter } from '../../../Assets/Slices/searchSlice';
import * as styled from './styles.js';

const MobileFilteredSearch = () => {
	const dispatch = useDispatch();
	const isFilterMenuOpen = useSelector((state) => state.search.openBoolean);

	const handleMobileFilterClick = () => {
		isFilterMenuOpen ? dispatch(closeFilter()) : dispatch(openFilter());
	};

	return (
		<styled.MobileFiltersWrapper>
			<FilteredSearch />
			<styled.MobileIconWrapper
				isFilterMenuOpen={isFilterMenuOpen}
				onClick={() => handleMobileFilterClick()}
			>
				<styled.FiltersButtonText>Filters</styled.FiltersButtonText>
				<BiSlider />
			</styled.MobileIconWrapper>
		</styled.MobileFiltersWrapper>
	);
};
export default MobileFilteredSearch;
