import * as styled from './styles.js';
import { useDispatch, useSelector } from 'react-redux';
import {
	clearFilters,
	closeFilter,
	filterPriceOrganization,
	// filterPriceRange,
	filterQuery,
} from '../../Assets/Slices/searchSlice.js';
import { useEffect, useRef } from 'react';
import useMobileBreakPoint from '../../Assets/Helpers/useMobileBreakPoint.jsx';

const FilteredSearch = () => {
	const isMobileView = useMobileBreakPoint(768);
	// const [maxPrice, setMaxPrice] = useState(100);
	const formRef = useRef(null);
	const dispatch = useDispatch();
	const filters = useSelector((state) => state.search.filterOptionsArray);

	const handleFilter = (e) => {
		const newFilter = e.target.id;
		dispatch(filterQuery(newFilter));
	};

	// const handlePriceRangeChange = (e) => {
	// 	const newMaxPrice = Math.floor(Number(e.target.value));
	// 	setMaxPrice(newMaxPrice);
	// 	dispatch(filterPriceRange(newMaxPrice));
	// };

	const handlePriceOrg = (e) => {
		const priceOrg = e.target.id;
		dispatch(filterPriceOrganization(priceOrg));
	};

	const clear = (e) => {
		e.preventDefault();

		if (formRef.current) {
			formRef.current.reset();
		}
		// setMaxPrice(100);
		dispatch(clearFilters());
		dispatch(closeFilter());
	};

	useEffect(() => {
		if (filters.length !== 0) {
			filters.forEach((filter) => {
				const input = formRef.current.querySelector(`input[id='${filter}']`);

				if (input) {
					input.checked = true;
				}
			});
		}
	}, [filters]);

	const isFilterMenuOpen = useSelector((state) => state.search.openBoolean);

	return (
		<styled.FilteredSearchWrapper
			isMobileView={isMobileView}
			isFilterMenuOpen={isFilterMenuOpen}
		>
			<styled.FilterForm ref={formRef}>
				{/*Category*/}
				<styled.FilterTitle>Filter by Categories</styled.FilterTitle>
				<styled.FilterOption>
					<styled.FilterInput onClick={handleFilter} type='checkbox' id='hat' />
					<styled.FilterLabel htmlFor='hats'>Hats</styled.FilterLabel>
				</styled.FilterOption>

				<styled.FilterOption>
					<styled.FilterInput
						onClick={handleFilter}
						type='checkbox'
						id='shirt'
					/>
					<styled.FilterLabel htmlFor='shirts'>T-Shirts</styled.FilterLabel>
				</styled.FilterOption>

				<styled.FilterOption>
					<styled.FilterInput
						onClick={handleFilter}
						type='checkbox'
						id='hoodie'
					/>
					<styled.FilterLabel htmlFor='hoodies'>Hoodies</styled.FilterLabel>
				</styled.FilterOption>

				{/* <styled.FilterOption>
					<styled.FilterInput
						onClick={handleFilter}
						type='checkbox'
						id='faceMask'
					/>
					<styled.FilterLabel htmlFor='faceMask'>Face Mask</styled.FilterLabel>
				</styled.FilterOption>

				<styled.FilterOption>
					<styled.FilterInput
						onClick={handleFilter}
						type='checkbox'
						id='stickers'
					/>
					<styled.FilterLabel htmlFor='stickers'>Stickers</styled.FilterLabel>
				</styled.FilterOption>

				<styled.FilterOption>
					<styled.FilterInput
						onClick={handleFilter}
						type='checkbox'
						id='wristbands'
					/>
					<styled.FilterLabel htmlFor='wristbands'>
						Wristbands
					</styled.FilterLabel>
				</styled.FilterOption> */}

				{/*price*/}
				<styled.FilterTitle>Filter by Price</styled.FilterTitle>
				{/* <styled.FilterOption>
					<styled.RangeNumbers>0</styled.RangeNumbers>
					<styled.FilterInput
						type='range'
						min={0}
						max={100}
						onChange={handlePriceRangeChange}
					/>
					<styled.RangeNumbers>{maxPrice}</styled.RangeNumbers>
				</styled.FilterOption> */}

				<styled.FilterOption>
					<styled.FilterInput
						onClick={handlePriceOrg}
						type='radio'
						name='priceScale'
						id='lowestFirst'
					/>
					<styled.FilterLabel htmlFor='lowestFirst'>
						Low - High
					</styled.FilterLabel>
				</styled.FilterOption>

				<styled.FilterOption>
					<styled.FilterInput
						onClick={handlePriceOrg}
						type='radio'
						name='priceScale'
						id='highestFirst'
					/>
					<styled.FilterLabel htmlFor='highestFirst'>
						High - Low
					</styled.FilterLabel>
				</styled.FilterOption>

				{/*Clear Filters */}
				<styled.FilterOption>
					<styled.ClearFiltersButton onClick={clear}>
						Clear Filters
					</styled.ClearFiltersButton>
				</styled.FilterOption>
			</styled.FilterForm>
		</styled.FilteredSearchWrapper>
	);
};
export default FilteredSearch;
