import { styled } from 'styled-components';

export const MobileIconWrapper = styled.div`
	padding: 5px;
	margin: 15px 0 0 5px;

	border: 1px dashed ${(props) => props.theme.colors.chred};
	border-radius: 15px;

	display: flex;
	justify-content: center;
	align-items: center;

	position: absolute;

	transform: ${(props) =>
		props.isFilterMenuOpen ? 'translateX(195px)' : 'translateX(0)'};
	transition: ${(props) =>
		props.isFilterMenuOpen ? 'all 0.35s ease-out' : 'all 0.25s ease-in'};

	cursor: pointer;
`;

export const FiltersButtonText = styled.h3`
	margin-right: 5px;
`;

export const MobileFiltersWrapper = styled.div`
	display: flex;
`;
