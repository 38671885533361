import styled from 'styled-components';

export const FilteredSearchWrapper = styled.div`
	height: 100%;
	width: ${(props) => (props.isMobileView ? 'auto' : '250px')};

	transform: ${(props) =>
		props.isMobileView
			? props.isFilterMenuOpen
				? 'translateX(0)'
				: 'translateX(-100%)'
			: 'translateX(0)'};
	transition: all 0.35s ease-out;

	position: ${(props) => (props.isMobileView ? 'absolute' : 'static')};

	background-color: ${(props) =>
		props.isMobileView ? (props) => props.theme.colors.greyOpaque : 'none'};

	cursor: pointer;
`;

export const FilterForm = styled.form`
	margin: 15px 10px 0 10px;

	display: flex;
	flex-direction: column;
`;

export const FilterOption = styled.div`
	width: 100%;
	margin-bottom: 25px;

	display: flex;
`;

export const FilterTitle = styled.h2`
	margin-bottom: 15px;
`;

export const FilterInput = styled.input`
	margin-right: 5px;
`;

export const FilterLabel = styled.label``;

export const ClearFiltersButton = styled.button`
	border-radius: 15px;

	padding: 5px;
`;

export const PriceRangeWrapper = styled.div``;

export const RangeNumbers = styled.span``;
